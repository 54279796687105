<template>
  <div id="finishedOutLedgers">
    <el-dialog
      :title="finishedOutLedgersFormTitle"
      width="680px"
      :visible.sync="finishedOutLedgersDialogVisible"
      :close-on-click-modal="false"
      @close="finishedOutLedgersDialogClose"
    >
      <el-form
        ref="finishedOutLedgersFormRef"
        :model="finishedOutLedgersForm"
        :rules="finishedOutLedgersFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="name">
              <el-input v-model="finishedOutLedgersForm.name" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批号" prop="batchNo">
              <el-input v-model="finishedOutLedgersForm.batchNo" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格/装量" prop="spec">
              <el-input v-model="finishedOutLedgersForm.spec" placeholder="请输入规格/装量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验单号" prop="testNum">
              <el-input v-model="finishedOutLedgersForm.testNum" placeholder="请输入检验单号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="outDate">
              <el-date-picker v-model="finishedOutLedgersForm.outDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出库单号" prop="outNo">
              <el-input v-model="finishedOutLedgersForm.outNo" placeholder="请输入出库单号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="去向" prop="whereabouts">
              <el-input v-model="finishedOutLedgersForm.whereabouts" placeholder="请输入去向" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位" prop="unit">
              <el-input v-model="finishedOutLedgersForm.unit" placeholder="请输入单位" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收入数量" prop="inAmount">
              <el-input v-model="finishedOutLedgersForm.inAmount" placeholder="请输入收入数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发出数量" prop="outAmount">
              <el-input v-model="finishedOutLedgersForm.outAmount" placeholder="请输入发出数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结存" prop="balance">
              <el-input v-model="finishedOutLedgersForm.balance" placeholder="请输入结存" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="finishedOutLedgersForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="finishedOutLedgersDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="finishedOutLedgersFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品名称">
        <el-input v-model="searchForm.name" placeholder="请输入产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="finishedOutLedgersPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="name" label="产品名称" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="spec" label="规格/装量" />
      <el-table-column prop="testNum" label="检验单号" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.outDate">{{ scope.row.outDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="outNo" label="出库单号" />
      <el-table-column prop="whereabouts" label="去向" />
      <el-table-column prop="unit" label="单位" />
      <el-table-column prop="inAmount" label="收入数量" />
      <el-table-column prop="outAmount" label="发出数量" />
      <el-table-column prop="balance" label="结存" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="finishedOutLedgersPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addFinishedOutLedgers, deleteFinishedOutLedgers, updateFinishedOutLedgers, selectFinishedOutLedgersInfo, selectFinishedOutLedgersList } from '@/api/storage/finishedOutLedgers'

export default {
  data () {
    return {
      finishedOutLedgersDialogVisible: false,
      finishedOutLedgersFormTitle: '',
      finishedOutLedgersForm: {
        id: '',
        name: '',
        batchNo: '',
        spec: '',
        testNum: '',
        outDate: '',
        outNo: '',
        whereabouts: '',
        unit: '',
        inAmount: '',
        outAmount: '',
        balance: '',
        remarks: ''
      },
      finishedOutLedgersFormRules: {
        name: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      finishedOutLedgersPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      }
    }
  },
  created () {
    selectFinishedOutLedgersList(this.searchForm).then(res => {
      this.finishedOutLedgersPage = res
    })
  },
  methods: {
    finishedOutLedgersDialogClose () {
      this.$refs.finishedOutLedgersFormRef.resetFields()
    },
    finishedOutLedgersFormSubmit () {
      if (this.finishedOutLedgersFormTitle === '成品出库分类账详情') {
        this.finishedOutLedgersDialogVisible = false
        return
      }
      this.$refs.finishedOutLedgersFormRef.validate(async valid => {
        if (valid) {
          if (this.finishedOutLedgersFormTitle === '新增成品出库分类账') {
            await addFinishedOutLedgers(this.finishedOutLedgersForm)
          } else if (this.finishedOutLedgersFormTitle === '修改成品出库分类账') {
            await updateFinishedOutLedgers(this.finishedOutLedgersForm)
          }
          this.finishedOutLedgersPage = await selectFinishedOutLedgersList(this.searchForm)
          this.finishedOutLedgersDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.finishedOutLedgersFormTitle = '新增成品出库分类账'
      this.finishedOutLedgersDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteFinishedOutLedgers(row.id)
        if (this.finishedOutLedgersPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.finishedOutLedgersPage = await selectFinishedOutLedgersList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.finishedOutLedgersFormTitle = '修改成品出库分类账'
      this.finishedOutLedgersDialogVisible = true
      this.selectFinishedOutLedgersInfoById(row.id)
    },
    handleInfo (index, row) {
      this.finishedOutLedgersFormTitle = '成品出库分类账详情'
      this.finishedOutLedgersDialogVisible = true
      this.selectFinishedOutLedgersInfoById(row.id)
    },
    selectFinishedOutLedgersInfoById (id) {
      selectFinishedOutLedgersInfo(id).then(res => {
        this.finishedOutLedgersForm.id = res.id
        this.finishedOutLedgersForm.name = res.name
        this.finishedOutLedgersForm.batchNo = res.batchNo
        this.finishedOutLedgersForm.spec = res.spec
        this.finishedOutLedgersForm.testNum = res.testNum
        this.finishedOutLedgersForm.outDate = res.outDate
        this.finishedOutLedgersForm.outNo = res.outNo
        this.finishedOutLedgersForm.whereabouts = res.whereabouts
        this.finishedOutLedgersForm.unit = res.unit
        this.finishedOutLedgersForm.inAmount = res.inAmount
        this.finishedOutLedgersForm.outAmount = res.outAmount
        this.finishedOutLedgersForm.balance = res.balance
        this.finishedOutLedgersForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectFinishedOutLedgersList(this.searchForm).then(res => {
        this.finishedOutLedgersPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectFinishedOutLedgersList(this.searchForm).then(res => {
        this.finishedOutLedgersPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectFinishedOutLedgersList(this.searchForm).then(res => {
        this.finishedOutLedgersPage = res
      })
    }
  }
}
</script>

<style>
</style>
