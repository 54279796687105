import axios from '@/common/axios'
import qs from 'qs'

export function addFinishedOutLedgers (data) {
  return axios({
    method: 'post',
    url: '/storage/out/add',
    data: qs.stringify(data)
  })
}

export function deleteFinishedOutLedgers (id) {
  return axios({
    method: 'delete',
    url: '/storage/out/delete/' + id
  })
}

export function updateFinishedOutLedgers (data) {
  return axios({
    method: 'put',
    url: '/storage/out/update',
    data: qs.stringify(data)
  })
}

export function selectFinishedOutLedgersInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/out/info/' + id
  })
}

export function selectFinishedOutLedgersList (query) {
  return axios({
    method: 'get',
    url: '/storage/out/list',
    params: query
  })
}
